<template>
  <section id="reset-password">
    <v-form
      id="reset-password-form"
      ref="resetPassword"
      @submit.prevent="submitHandler"
      v-model="isFormValid"
    >
      <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-center">
          <h3 class="text--primary font-weight-large secondary--font pb-6">
            {{ title }}
          </h3>
        </v-col>
      </v-row>
      <slot name="message" />
      <v-row no-gutters class="pt-1">
        <v-col cols="12">
          <password-input-field
            is-required
            label="New Password"
            :password="formData.password"
            :rules="[isNewPasswordValid]"
            @update-password="formData.password = $event"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-1">
        <v-col cols="12">
          <password-input-field
            is-required
            label="Confirm New Password"
            :password="formData.confirmPassword"
            :rules="[validateConfirmPassword, isConfirmPasswordSame]"
            @update-password="formData.confirmPassword = $event"
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <password-rules-renderer
        wrapper-class="py-2 text-start"
        :get-icon-color="getIconColor"
        :password-rules="passwordRules"
        :is-new-password-dirty="isNewPasswordDirty"
      />

      <v-row>
        <v-col cols="12" class="mt-1">
          <v-btn
            :loading="isLoading"
            x-large
            :disabled="!isFormValid || isFormDisabled"
            type="submit"
            block
            class="button font-weight-bold white--text"
            color="dark-black"
            v-track="'reset-password-submit-btn'"
          >
            {{ btnText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { maxLength } from "@/validators";
import PasswordValidatorMixin from "@/mixins/PasswordValidator.mixin";
import PasswordInputField from "@/components/shared/PasswordInputField.vue";
import PasswordRulesRenderer from "@/components/shared/PasswordRulesRenderer.vue";
/**
 * Reset Password form
 * @author {Jatin Kamboj}
 */
export default {
  name: "ResetPasswordForm",
  /**
   * ---------------- Mixins ----------------
   */
  mixins: [PasswordValidatorMixin],
  /**
   * ---------------- Components ----------------
   */
  components: { PasswordInputField, PasswordRulesRenderer },
  /**
   * ---------------- Components ----------------
   */
  props: {
    title: { type: String, required: true },
    onSubmitHandler: { type: Function, required: true },
    btnText: { type: String, required: true },
    isLoading: { type: Boolean, required: true, default: false },
    isFormDisabled: { type: Boolean, default: false },
    displayMessages: { type: Object, required: true, default: () => ({}) },
  },
  /**
   * ---------------- Data properties ----------------
   */
  data() {
    return {
      isFormValid: false,
    };
  },
  /**
   * ---------------- Methods ----------------
   */
  methods: {
    maxLength,
    /**
     * Submit handler
     */
    submitHandler() {
      if (!this.$refs.resetPassword.validate()) {
        return false;
      }
      this.onSubmitHandler(this.formData);
    },
  },
};
</script>
<style scoped>
.errors {
  font-size: 0.75em;
}
</style>
