<template>
  <section id="reset-password">
    <auth-forms-wrapper width="390px" height="" card-classes="px-0" #form>
      <div id="reset-password-wrapper">
        <reset-password-form
          ref="form"
          :title="displayMessages.title"
          :on-submit-handler="onSubmitHandler"
          :is-loading="isLoading"
          btn-text="Reset Password"
          :display-messages="displayMessages"
          :is-form-disabled="!isOobCodeExists"
        >
          <template #message>
            <alert-message
              v-show="!!message"
              :message="message"
              classes="py-3 mb-6"
              :alert-type="messageType"
            />
          </template>
        </reset-password-form>
      </div>
    </auth-forms-wrapper>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import ResetPasswordForm from "@/components/forms/ResetPasswordForm.vue";
import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

import {
  verifyPasswordResetTokenOrOobCode,
  confirmOobCodeAndResetPassword,
} from "@/services/auth";
import { defer } from "@/utils";
import AlertMessage from "@/components/shared/AlertMessage.vue";
import { AUTH_GIP_ERROR_CODES } from "@/constants/app";

/** Expired token auth codes */
const EXPIRED_TOKEN_CODE = [
  "auth/invalid-action-code",
  "auth/expired-action-code",
];

/**
 * Reset Password view
 * @author {Jatin Kamboj}
 */
export default {
  name: "ResetPassword",
  /**
   * ---------------- Components ----------------
   */
  components: {
    AuthFormsWrapper,
    ResetPasswordForm,
    AlertMessage,
  },
  /**
   * ---------------- Data properties ----------------
   */
  data() {
    return {
      isLoading: false,
      message: "",
      messageType: "",
    };
  },
  /**
   * ---------------- Computed properties ----------------
   */
  computed: {
    /**
     * Error messages
     * @description Messages fetched from the app dictionary
     */
    displayMessages() {
      const { form, errorMessages, successMessages } =
        this.$dictionary.app.resetPassword;
      return { ...form, ...errorMessages, ...successMessages };
    },
    /**
     * Determines whether oobCode exists
     * @description oobCode is a firebase token which is used to reset user password
     * @type {Boolean}
     */
    isOobCodeExists() {
      return !!this.$route?.query?.oobCode;
    },
  },
  /**
   * ---------------- Methods ----------------
   */
  methods: {
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    showMessage({ message, type = "error" }) {
      this.message = message;
      this.messageType = type;
    },
    /**
     * Handles form submition and resets the user with new password entered by him/her
     */
    async onSubmitHandler(formData) {
      // Stops execution of function if oobCode is not present
      if (!this.isOobCodeExists) return;
      try {
        this.isLoading = true;

        /** Checks if the oobCode is valid */
        const isValid = await verifyPasswordResetTokenOrOobCode(
          this.$route?.query?.oobCode
        );

        if (isValid) {
          await confirmOobCodeAndResetPassword(
            this.$route?.query?.oobCode,
            formData.confirmPassword
          );

          this.showMessage({
            message: this.displayMessages.passwordChanged,
            type: this.$appConfig.snackbar.snackbarTypes.success,
          });

          this.redirectToLogin();
        }
      } catch (error) {
        this.exceptionHandler(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Delays and redirects user to login page
     */
    redirectToLogin() {
      const timeoutRef = defer(() => {
        this.$router.replace("/login");
        clearTimeout(timeoutRef);
      }, 2000);
    },
    /**
     * Handles all the api exceptions and display error messages
     */
    exceptionHandler(error = {}) {
      let { code, message = "" } = error;

      if (code) {
        if (code === AUTH_GIP_ERROR_CODES.weakPassword) {
          message = this.displayMessages.weakPassword;
        } else if (EXPIRED_TOKEN_CODE.includes(code)) {
          message = this.displayMessages.linkTokenExpired;
        } else if (code === AUTH_GIP_ERROR_CODES.accountDisabled) {
          message = this.displayMessages.accountDisabled;
        }
      }
      this.showMessage({ message });
    },
  },
};
</script>

<style scoped>
.v-text-field__details {
  margin: 0 !important  ;
}
</style>
